<template>
    <div class="row mb-2">
      <div class="col-md-3">
        <cardEntitasNea />
      </div>
      <div class="col-md-9">
        <b-card no-body class="h-100">
          <b-card-body>
            <!-- NETWORK GRAPH -->
            <networkGraph />
          </b-card-body>
        </b-card>
      </div>
    </div>
  </template>
  <script>
  import { BCard, BCardHeader, BCardTitle, BCardBody } from "bootstrap-vue";
  import cardEntitasNea from "@/components/analytic/nea/cardEntitasNea.vue";
  import networkGraph from "@/components/usable/networkGraph.vue";
  
  export default {
    name: "SNA",
    components: {
      BCard,
      BCardHeader,
      BCardTitle,
      BCardBody,
      cardEntitasNea,
      networkGraph,
    },
  
    data: () => ({
      title: "Named Entity Analysis | Online Media Monitoring",
    }),
  
    created() {
      document.title = this.title;
    },
  };
  </script>
  